/* eslint-disable prefer-destructuring */
import React from 'react';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import page from '@/layouts/page';
import Head from '@/componentWebs/head';
// import { renderTemplatesCategory } from '@/GroupTemplate/templateCategory';

const RenderTemplatesCategory = dynamic(() => import('@/GroupTemplate'), {
  ssr: true,
  loading: () => null,
});

const { publicRuntimeConfig } = getConfig();
// @page
@connect(({ webs }) => ({
  webs,
}))
class Index extends React.Component {

  render() {
    const { modifiedCategoryAll, dataSite } = this.props;
    const ogImage = dataSite && dataSite.logo && dataSite.logo[0];
    let url;
    if (typeof window !== 'undefined') {
      url = window.location.href;
    }
    // console.log('modifiedCategoryAll', modifiedCategoryAll);
    return (
      <React.Fragment>
        <Head
          dataSite={dataSite}
          ogImage={ogImage}
          keywords={dataSite && dataSite.seoKeywords}
          description={dataSite && dataSite.seoDescriptions}
          url={url || ''}
        />
        {modifiedCategoryAll &&
          modifiedCategoryAll.length > 0 &&
          modifiedCategoryAll.map((item) =>
            <RenderTemplatesCategory data={[item]} dataSite={dataSite} key={item.id} /> // add key
          )}
      </React.Fragment>
    );
  }
}

export default page(Index);
